/*
phone: 0 ~ 600px
tab-port: 600px ~ 900px
tab-land: 900px ~ 1200px
desktop(default): 1200px ~ 1800px
big-desktop: 1800px ~ infinity
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) {
            @content;
        } //37.5em = 600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) {
            @content;
        } //56.25em = 900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) {
            @content;
        } //75em = 1200px
    }
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) {
            @content;
        } //112.5em = 1800px
    }
}
