* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  // define what 1rem is
  font-size: 62.5%; //1rem = 16px * 62.5% = 10px

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 16px * 75% = 12px
  }

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 16px * 56.25% = 9px
  }

  @include respond(tab-port) {
    font-size: 50%; //1rem = 16px * 50% = 8px
  }
  @include respond(phone) {
    font-size: 37.5%; //1rem = 16px * 37.5% = 6px
  }
}
